import { createContext, useContext, useReducer, useMemo } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// React main context
const Admin = createContext();

// Setting custom name for the context which is visible on react dev tools
Admin.displayName = "AdminContext";

// React reducer
function reducer(state, action) {
  switch (action.type) {
    case "MINI_SIDENAV":
      return { ...state, miniSidenav: action.value };
    case "MINI_VIEW_HEADER":
      return { ...state, miniViewHeader: action.value };
    case "TRANSPARENT_SIDENAV":
      return { ...state, transparentSidenav: action.value };
    case "WHITE_SIDENAV":
      return { ...state, whiteSidenav: action.value };
    case "SIDENAV_COLOR":
      return { ...state, sidenavColor: action.value };
    case "TRANSPARENT_NAVBAR":
      return { ...state, transparentNavbar: action.value };
    case "FIXED_NAVBAR":
      return { ...state, fixedNavbar: action.value };
    case "OPEN_CONFIGURATOR":
      return { ...state, openConfigurator: action.value };
    case "DIRECTION":
      return { ...state, direction: action.value };
    case "LAYOUT":
      return { ...state, layout: action.value };
    case "DARKMODE":
      return { ...state, darkMode: action.value };
    case "USER_EMAIL":
      return { ...state, userEmail: action.value };
    case "USER_ROLES":
      return { ...state, userRoles: action.value };
    case "ACTIVE_USER_ROLE":
      return { ...state, activeUserRole: action.value };
    case "USER_ID":
      return { ...state, userId: action.value };
    case "IS_LOGGED_IN":
      return { ...state, isLoggedIn: action.value };
    case "ORGANISATION_ID":
      return { ...state, organisationId: action.value };
    case "LOGOUT_URL":
      return { ...state, logoutURL: action.value };
    case "ACTIVE_ATLAS":
      return { ...state, activeAtlas: action.value };
    case "IS_DIRTY":
      return { ...state, isDirty: action.value };
    case "NAVIGATION_PENDING":
      return { ...state, navigationPending: action.value };
    case "ROLE_CHANGE_PENDING":
      return { ...state, roleChangePending: action.value };
    case "GUIDE_VISIBLE":
      return { ...state, guideVisible: action.value };
    case "ACTIVE_ATLAS_ID":
      return { ...state, activeAtlasId: action.value };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

// Context provider
function AdminControllerProvider({ children }) {
  const initialState = {
    miniSidenav: false,
    miniViewHeader: false,
    transparentSidenav: false,
    whiteSidenav: false,
    sidenavColor: "info",
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    direction: "ltr",
    layout: "frontpage",
    darkMode: false,
    userEmail: "",
    userRoles: ["frontpage"],
    activeUserRole: "frontpage",
    userId: 0,
    isLoggedIn: false,
    organisationId: 0,
    logoutURL: undefined,
    activeAtlas: undefined,
    isDirty: undefined,
    navigationPending: false,
    roleChangePending: false,
    guideVisible: false,
    activeAtlasId: null
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return <Admin.Provider value={value}>{children}</Admin.Provider>;
}

// React custom hook for using context
function useAdminController() {
  const context = useContext(Admin);
  if (!context)
    throw new Error(
      "useAdminController should be used inside the AdminControllerProvider."
    );

  return context;
}

// Typechecking props for the AdminControllerProvider
AdminControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });
const setMiniViewHeader = (dispatch, value) => dispatch({ type: "MINI_VIEW_HEADER", value });
const setTransparentSidenav = (dispatch, value) => dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setWhiteSidenav = (dispatch, value) => dispatch({ type: "WHITE_SIDENAV", value });
const setSidenavColor = (dispatch, value) => dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch, value) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch, value) => dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch, value) => dispatch({ type: "OPEN_CONFIGURATOR", value });
const setDirection = (dispatch, value) => dispatch({ type: "DIRECTION", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setDarkMode = (dispatch, value) => dispatch({ type: "DARKMODE", value });
const setUserEmail = (dispatch, value) => dispatch({ type: "USER_EMAIL", value });
const setUserRoles = (dispatch, value) => dispatch({ type: "USER_ROLES", value });
const setActiveUserRole = (dispatch, value) => dispatch({ type: "ACTIVE_USER_ROLE", value });
const setUserId = (dispatch, value) => dispatch({ type: "USER_ID", value });
const setIsLoggedIn = (dispatch, value) => dispatch({ type: "IS_LOGGED_IN", value });
const setOrganisationId = (dispatch, value) => dispatch({ type: "ORGANISATION_ID", value });
const setLogoutURL = (dispatch, value) => dispatch({ type: "LOGOUT_URL", value });
const setActiveAtlas = (dispatch, value) => dispatch({ type: "ACTIVE_ATLAS", value });
const setIsDirty = (dispatch, value) => dispatch({ type: "IS_DIRTY", value });
const setNavigationPending = (dispatch, value) => dispatch({ type: "NAVIGATION_PENDING", value });
const setRoleChangePending = (dispatch, value) => dispatch({ type: "ROLE_CHANGE_PENDING", value });
const setGuideVisible = (dispatch, value) => dispatch({ type: "GUIDE_VISIBLE", value });
const setActiveAtlasId = (dispatch, value) => dispatch({ type: "ACTIVE_ATLAS_ID", value });

export {
  AdminControllerProvider,
  useAdminController,
  setMiniSidenav,
  setMiniViewHeader,
  setTransparentSidenav,
  setWhiteSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
  setDarkMode,
  setUserEmail,
  setUserRoles,
  setActiveUserRole,
  setUserId,
  setIsLoggedIn,
  setOrganisationId,
  setLogoutURL,
  setActiveAtlas,
  setIsDirty,
  setNavigationPending,
  setRoleChangePending,
  setGuideVisible,
  setActiveAtlasId
};
